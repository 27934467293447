<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>Page List</h1>
      </div>
      <b-row>
        <b-col cols="6">
          <b-form @submit.prevent>
            <b-form-group label="Search" label-for="search">
              <b-form-input
                id="search"
                trim
                placeholder="Search..."
                v-model="searchCommand"
              />
            </b-form-group>
          </b-form>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Pages Language" v-slot="{ ariaDescribedby }">
            <div
              class="w-100 d-flex align-items-center justify-content-center"
              style="gap: 1rem"
            >
              <b-form-radio
                v-model="languageId"
                :aria-describedby="ariaDescribedby"
                name="isDeletable"
                :value="null"
              >
                All
              </b-form-radio>
              <b-form-radio
                v-model="languageId"
                :aria-describedby="ariaDescribedby"
                name="isDeletable"
                :value="1"
              >
                EN
              </b-form-radio>
              <b-form-radio
                v-model="languageId"
                :aria-describedby="ariaDescribedby"
                name="isDeletable"
                :value="2"
              >
                FA
              </b-form-radio>
            </div>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="pageList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="No items available"
          >
            <template #cell(createDate)="data">
              {{ new Date(data.item.createDate).toLocaleDateString("fa-IR") }}
            </template>
            <template #cell(pageShapeType)="data">
              {{ pageShapeTypes[data.item.pageShapeType] }}
            </template>
            <template #cell(link)="data">
              {{ data.item.link }}
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-start" style="gap: 2rem">
                <div>
                  <add-edit-delete-button
                    @renderDeleteModal="renderDeleteModal"
                    :data="generateObjectForAddEditRemoveButton(data.item)"
                    :routerCreateName="'pages-create-page'"
                    :routerEditName="'pages-edit-page'"
                  ></add-edit-delete-button>
                </div>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
      <!-- Delete Modal Start -->
      <b-modal
        id="modal-delete"
        centered
        ok-variant="danger"
        title="Delete a page"
        ok-title="Delete"
        cancelTitle="Dismiss"
        @ok="sendPageDeleteRequest"
      >
        <template v-if="tempPage">
          <span> Are you sure you want to delete this page?</span>
        </template>
      </b-modal>
      <!-- Delete Modal End -->
    </b-card>
  </b-overlay>
</template>
<script>
export default {
  title: "Page List",
  mounted() {
    this.getAllPages();
  },
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      searchCommand: null,
      languageId: null,
      pageList: null,
      myTableColumns: [
        {
          key: "pageId",
          label: "Page Id",
        },
        {
          key: "title",
          label: "Page Title",
        },
        {
          key: "link",
          label: "Page Link",
        },
        {
          key: "pageShapeType",
          label: "Page Type",
        },
        {
          key: "createDate",
          label: "Create Date",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      pageShapeTypes: {
        1: "Homepage",
        2: "Blog",
        3: "Contact Us",
        4: "Representetive",
        5: "Service",
        6: "Dynamic",
      },
      tempPage: null,
      langsObject: null,
    };
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllPages();
      },
    },
    searchCommand: {
      handler(val) {
        this.getAllPages();
      },
    },
    languageId: {
      handler(val) {
        this.getAllPages();
      },
    },
  },
  methods: {
    generateObjectForAddEditRemoveButton(pageDetails) {
      return {
        id: pageDetails.pageId,
        languageId: pageDetails.languageId,
        otherLanguages: pageDetails.otherLanguages || [],
        mainId: pageDetails.mainId,
      };
    },
    findIdInOtherLanguages(preRoute, data) {
      let selected = data.find((item) => {
        if (item.preRoute == preRoute) {
          return item;
        }
      });
      if (selected) {
        return selected.objectId;
      }
    },
    redirectToEdit(id) {
      this.$router.push({
        name: "pages-edit-page",
        params: {
          id,
        },
      });
    },
    redirectToCreate(mainId, langId) {
      this.$router.push({
        name: "pages-create-page",
        params: {
          langId,
          mainId,
        },
      });
    },
    doesDataIncludes(preRoute, data) {
      if (data) {
        let selected = data.find((item) => {
          if (item.preRoute == preRoute) {
            return item;
          }
        });
        if (selected) {
          return true;
        }
      }
    },
    closeLangsModal() {
      this.$bvModal.hide("modal-langs");
      this.tempPage = null;
    },
    renderLangsModal(page) {
      this.tempPage = JSON.parse(JSON.stringify(page));
      setTimeout(() => {
        this.$bvModal.show("modal-langs");
      }, 100);
    },
    async sendPageDeleteRequest() {
      try {
        this.isLoading = true;
        let _this = this;
        let deleteAPage = new DeleteAPage(_this);
        deleteAPage.setRequestParam({
          id: _this.tempPage.id,
        });
        await deleteAPage.fetch((response) => {
          if (response.isSuccess) {
            _this.$toast({
              component: ToastificationContent,
              position: "bottom-center",
              props: {
                title: "Operation Was Successful",
                icon: "CheckIcon",
                variant: "success",
                text: "Page Deleted Successfully",
              },
            });
            _this.getAllPages();
            _this.tempPage = null;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    renderDeleteModal(page) {
      this.tempPage = JSON.parse(JSON.stringify(page));
      this.$bvModal.show("modal-delete");
    },
    async getAllPages() {
      try {
        this.isLoading = true;
        let _this = this;
        let qParams = {
          count: this.count,
          pageNumber: this.pageNumber,
        };
        if (this.searchCommand) {
          qParams["searchCommand"] = this.searchCommand;
        }
        if (this.languageId) {
          qParams["languageId"] = this.languageId;
        }
        let getAllPages = new GetAllPages(_this);
        getAllPages.setRequestParam(qParams);
        await getAllPages.fetch((response) => {
          if (response.isSuccess) {
            _this.totalCount = response.data.formsCount;
            _this.pageList = response.data.forms;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BPagination,
    BFormRow,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BTable,
    BMediaAside,
    BMediaBody,
    BForm,
    BFormRadio,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
    BFormTextarea,
    BOverlay,
    BFormSelect,
    NewLangsModal,
    AddEditDeleteButton,
  },
};
import NewLangsModal from "@/views/components/utils/NewLangsModal.vue";
import { GetAllPages, DeleteAPage } from "@/libs/Api/Page";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {
  BContainer,
  BPagination,
  BFormRow,
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BTable,
  BMediaAside,
  BFormRadio,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormTextarea,
  BOverlay,
  BFormSelect,
} from "bootstrap-vue";
</script>
